import React from "react"
import Layout from "../../components/Layout"
import styled from "styled-components"
import RequestDemoForm from "../../components/individualProductPage/RequestDemoForm"
import StealBackground from "../../components/individualProductPage/images/steal-background.jpg"
import SpecBarMachineImage from "../../components/individualProductPage/images/gtr-profile.png"
import UsaBackground from "../../components/individualProductPage/images/handmade-in-usa-banner-gtr.jpg"
import MachineOverviewImage from "../../components/individualProductPage/images/gtr-machineoverview.jpg"
import HeavyDutyBackground from "../../components/individualProductPage/images/heavy-duty-banner-gtr.jpg"
import MachineDimensionImage from "../../components/individualProductPage/images/gtr-dims.jpg"
import RideOnTBTJ from "../../../static/FC-RIDER-YBTJ-LANDSCAPE.pdf"
import "../product-page-styles.scss"

//Thumbnail Images
import CylindricalThumb1Image from "../../components/individualProductPage/images/gtr-37C-1.jpg"
import CylindricalThumb2Image from "../../components/individualProductPage/images/gtr-37C-4.jpg"
import CylindricalThumb3Image from "../../components/individualProductPage/images/gtr-37C-3.jpg"
import CylindricalThumb4Image from "../../components/individualProductPage/images/gtr-37C-2.jpg"
import CylindricalThumb5Image from "../../components/individualProductPage/images/gtr-37C-5.jpg"
import DiskThumb1 from "../../components/individualProductPage/images/gtr-36D-black-1.jpg"
import DiskThumb2 from "../../components/individualProductPage/images/gtr-36D-black-4.jpg"
import DiskThumb3 from "../../components/individualProductPage/images/gtr-36D-black-3.jpg"
import DiskThumb4 from "../../components/individualProductPage/images/gtr-36D-black-2.jpg"
import DiskThumb5 from "../../components/individualProductPage/images/gtr-36D-black-5.jpg"
//End Thumbnail Images

//Main Images
import CylindricalMain1Image from "../../components/individualProductPage/images/gtr-37C-1.jpg"
import CylindricalMain2Image from "../../components/individualProductPage/images/gtr-37C-4.jpg"
import CylindricalMain3Image from "../../components/individualProductPage/images/gtr-37C-3.jpg"
import CylindricalMain4Image from "../../components/individualProductPage/images/gtr-37C-2.jpg"
import CylindricalMain5Image from "../../components/individualProductPage/images/gtr-37C-5.jpg"
import DiskMain1Image from "../../components/individualProductPage/images/gtr-36D-black-1.jpg"
import DiskMain2Image from "../../components/individualProductPage/images/gtr-36D-black-4.jpg"
import DiskMain3Image from "../../components/individualProductPage/images/gtr-36D-black-3.jpg"
import DiskMain4Image from "../../components/individualProductPage/images/gtr-36D-black-2.jpg"
import DiskMain5Image from "../../components/individualProductPage/images/gtr-36D-black-5.jpg"
import MediaTile from "../../components/individualProductPage/MediaTile"
import MainSpecBar from "../../components/individualProductPage/FeaturedProduct/MainSpecBar"
import MadeInUSA from "../../components/individualProductPage/MadeInUSA"
import SectionTitle from "../../components/common/SectionTitle"
import MachineOverview from "../../components/individualProductPage/MachineOverview"
import HeavyDuty from "../../components/individualProductPage/HeavyDuty"
import MachineDimensions from "../../components/individualProductPage/MachineDimensions"

import { OptionsBar } from "../../components/individualProductPage/FeaturedProduct/FeaturedProductStyles/OptionsBar"
//End Main Images

import MiniHdOptions from "../../components/individualProductPage/MiniHdOptions"

import ControllerImage from "../../components/individualProductPage/images/choose-your-controller-box-riders-v2.jpg"

import SEO from "../../components/SEO"
import Head from "../../components/Head"
import IndividualMachine from "../../components/individualProductPage/individualMachine"

const SizeBtnContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
`

const MediaTilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 840px) {
    max-width: 650px;
  }
`
const ControllerImageContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`

const GtrProductPage = props => {
  return (
    <>
      <SEO title="GTR v2.1" />
      <Head title="GTR v2.1" />
      <Layout>
        <IndividualMachine
          displayOrbital="none"
          cylindricalThumb1={CylindricalThumb1Image}
          cylindricalThumb2={CylindricalThumb2Image}
          cylindricalThumb3={CylindricalThumb3Image}
          cylindricalThumb4={CylindricalThumb4Image}
          cylindricalThumb5={CylindricalThumb5Image}
          cylindricalMain1={CylindricalMain1Image}
          cylindricalMain2={CylindricalMain2Image}
          cylindricalMain3={CylindricalMain3Image}
          cylindricalMain4={CylindricalMain4Image}
          cylindricalMain5={CylindricalMain5Image}
          diskThumb1={DiskThumb1}
          diskThumb2={DiskThumb2}
          diskThumb3={DiskThumb3}
          diskThumb4={DiskThumb4}
          diskThumb5={DiskThumb5}
          diskMain1={DiskMain1Image}
          diskMain2={DiskMain2Image}
          diskMain3={DiskMain3Image}
          diskMain4={DiskMain4Image}
          diskMain5={DiskMain5Image}
          brochureLink="https://www.dropbox.com/s/u77dwgm4t3xffk6/FC-V2-RIDERS-SM.pdf?dl=0"
          techLink="/fatorycat-rider-v2-techspec.pdf"
          partsLink="/FC-GTR-V2-1-P-EN.pdf"
          opLink="/FC-RIDERS-V2-1-OP-EN.pdf"
          title="GTR v2.1 - Ride On Floor Scrubber"
          textContent="With a compact footprint and larger capacity tanks and scrub width, the GTR ride-on auto scrubber differentiates itself by navigating better and fitting in environments other large capacity machines will not. With exceptional sight lines and optional side brooms, cleaning right up to the floor edge is simple.  Add on heavy duty side doors and for extra protection of your scrub deck should the operator hit an obstacle or debris. "
          pageUrl=""
          specifications="Cleaning Path: 29-37 | Run Time: Up To 5.0 Hours | Tank Capacity: Sol: 46 Gal, Rec: 49 Gal"
          videoSource="https://www.youtube.com/embed/NaGoDJxcSGM"
          upLink="/ride-on-floor-scrubbers/xr"
          machineName="GTR v2.1"
          downLink="/ride-on-floor-scrubbers/gtx"
          pmLink="/Rider-V2-PM.pdf"
          mtLink="https://www.dropbox.com/s/sx03160k2ppabfl/daily-weekly-maintenance-premium-v21306.pdf?dl=0"
        />
        <div className="tiles-container">
          <MediaTile
            title="Why FactoryCat ?"
            subTitle="Walk Behind Floor Scrubbers"
            link={RideOnTBTJ}
          />
          <MediaTile
            title="Videos"
            subTitle="Walk Behind Floor Scrubbers"
            link="/videos"
          />
          <MediaTile
            link="/images#ride-on-scrubbers"
            title="Images"
            subTitle="Walk Behind Floor Scrubbers"
          />
          <MediaTile
            title="Applications"
            subTitle="Walk Behind Floor Scrubbers"
            link="/applications"
          />
        </div>
        <MainSpecBar
          bgImage={StealBackground}
          machineImage={SpecBarMachineImage}
          myColor="white"
          machineName="GTR 2.1"
          category="Ride On Floor Scrubber"
          path='29 - 37"'
          path2="(73.7 - 91.4 cm)"
          runTime="5.0 Hours"
          solution="Solution: 46 Gal"
          solutionLiter="(174.1L)"
          recovery="Recovery: 49 Gal"
          recoveryLiter="(185.5L)"
        />
        <h1
          style={{
            margin: "3rem",
            textAlign: "center",
            color: "red",
            fontSize: "3em",
          }}
        >
          Choose Your Controller
        </h1>
        <ControllerImageContainer>
          <img src={ControllerImage} alt="" />
        </ControllerImageContainer>
        <MadeInUSA bgImage={UsaBackground} />
        <div className="spec-title-wrapper">
          <SectionTitle title="Machine Overview" />
        </div>
        <MachineOverview image={MachineOverviewImage} />
        <HeavyDuty
          bgImage={HeavyDutyBackground}
          innerText="HEAVY DUTY CLEANING"
        />
        <MachineDimensions
          image={MachineDimensionImage}
          sizeTitle="Size (L x W x H):"
          size='54" x 26" x 55'
          weightTitle="Weight (w/o batteries):"
          weight="755 - 805 lbs"
          voltageTitle="System Voltage:"
          voltage="36 VDC"
          stdBatteryTitle="Standard Battery:"
          battery="245 ah WET"
          powerTitle="Power:"
          power="0.75hp"
          tankTitle="Tank Material:"
          tank="Poly 3/8"
        />
        {/* <OptionsBar>Options</OptionsBar>
        <MiniHdOptions /> */}

        <div id="demo-form"></div>
        <div className="spec-title-wrapper">
          <SectionTitle title="Request a Demo" />
        </div>
        <RequestDemoForm image={CylindricalMain1Image} />
      </Layout>
    </>
  )
}

export default GtrProductPage
